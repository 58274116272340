<mat-sidenav-container class="sidenav-container">

  <mat-sidenav #sidenav class="sidenav" [fixedInViewport]="fixedInViewPort"
    [attr.role]="isHandset() ? 'dialog' : 'navigation'"
    [mode]="isHandset() ? 'over' : 'side'" [opened]="!isHandset()">

    <app-nav-tree></app-nav-tree>

  </mat-sidenav>

  <mat-sidenav-content>

    @if (loading()) {
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    }

    <jtms-alert-header />
    <router-outlet></router-outlet>

  </mat-sidenav-content>

</mat-sidenav-container>
