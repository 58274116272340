<div class="container">


    @if(showConnections()) {
    <small>{{part?.topConnection}}</small>
    }


  <img [ngSrc]="imageUrl" [width]="width()" [height]="height()"
    onerror="javascript:this.src='assets/icons/icon-64x64.png'">


    @if(showConnections()) {
    <small>{{part?.bottomConnection}}</small>
    }


</div>
