import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  {
    path: 'auth',
    loadChildren: () => import('@dis/auth').then((m) => m.authRoutes),
  },

  {
    path: 'about',
    loadChildren: () => import('./feature/about/about.routes'),
  },

  // {
  //   path: 'dataconnect',
  //   loadChildren: () => import('./feature/dataconnect/dataconnect.routes')
  // },

  {
    path: 'home',
    loadChildren: () => import('./feature/home/home.routes'),
  },

  {
    path: 'dataconnect',
    loadChildren: () => import('./feature/dataconnect/dataconnect.routes')
  },

  {
    path: 'dashboard/jobs-by-status',
    loadChildren: () => import('./feature/dashboards/jobs-by-status/jobs-by-status.routes'),
  },

  {
    path: 'dashboard/staged-jobs',
    loadChildren: () => import('./feature/dashboards/staged-jobs/staged-jobs.routes'),
  },

  {
    path: 'dashboard/jobs-gantt',
    loadChildren: () => import('projects/jtms-front-end/src/app/dashboards/jobs-gantt-charts/jobs-gantt-charts.routes'),
  },

  {
    path: 'dashboard/meeting-mode',
    loadChildren: () => import('projects/jtms-front-end/src/app/dashboards/meeting-mode/meeting-mode.routes'),
  },

  {
    path: 'dashboard/tools-bar',
    loadChildren: () => import('./charts/tools-bar/tools-bar.routes'),
  },

  {
    path: 'quotes',
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/0-quotes/0-quotes.routes'),
  },

  {
    path: 'inventory',
    loadChildren: () => import('./feature/inventory/inventory.routes'),
  },

  // {
  //   path: 'job-tool-management-dashboard',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('projects/jtms-front-end/src/app/dashboards/job-tool-management-dashboard/job-tool-management.module').then(m => m.JobToolManagementModule)
  // },

  {
    path: 'projects',
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/1_projects/1-projects.routes')
  },

  {
    path: 'jobs',
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/2_jobs/2-jobs.routes'),
  },

  {
    path: 'builds',
    loadChildren: () => import('./feature/builds/builds.routes'),
  },

  {
    path: 'assembly',
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/4_assembly/4-assembly.routes'),
  },

  {
    path: 'components',
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/5_components/5-components.routes'),
  },

  {
    path: 'containers',
    loadChildren: () => import('./containers/containers.routes'),
  },

  {
    path: 'parts',
    loadChildren: () => import('projects/jtms-front-end/src/app/jtms/6_parts/6-parts.routes'),
  },

  {
    path: 'office',
    loadChildren: () => import('./feature/office/office.routes')
  },

  {
    path: 'shop',
    loadChildren: () => import('projects/jtms-front-end/src/app/shop/shop.routes'),
  },

  {
    path: 'settings',
    loadChildren: () => import('./feature/settings/settings.routes'),
  },

  {
    path: '**',
    loadChildren: () => import('./feature/page-not-found/page-not-found.routes'),
  },
];
