import { Inject, Injectable, isDevMode } from '@angular/core';
// import { Router } from '@angular/router';

import { from, Observable } from 'rxjs';
import { distinctUntilKeyChanged, filter, skipUntil, skipWhile, switchMap } from 'rxjs/operators';

import { SheetsMetadataService } from './sheetsMetadata.service';
import { SnackbarService } from './snackbar.service';

import { AuthService, AuthState } from '@dis/auth';
import { AppScriptsApiService, GoogleDriveApiService, SheetsApiService } from '@dis/gapi';
import { COLLECTIONS, FOLDERID, SSID, BaseData, SheetsMetadata } from '@dis/models';
import { Store } from '@ngxs/store';

@Injectable()
export abstract class SheetsStoreService<T extends BaseData> {
  /**
   * @description This will be the name of the sheet
   */
  protected abstract collectionName: COLLECTIONS;

  protected collectionSheet?: string;
  protected collectionRows?: string;

  /** @deprecated */
  protected range?: string = null;

  private serviceName = `SheetStore`;

  public sheetUpdatedAt: any;

  constructor(
    @Inject(AppScriptsApiService) protected appsScriptService: AppScriptsApiService,
    @Inject(AuthService) protected authService: AuthService,
    @Inject(GoogleDriveApiService) protected driveApi: GoogleDriveApiService,
    @Inject(SheetsApiService) protected sheetsApiService: SheetsApiService,
    @Inject(SheetsMetadataService) protected sheetsMetadataService: SheetsMetadataService,
    @Inject(SnackbarService) protected snackbar: SnackbarService,
    @Inject(Store) protected store: Store,
  ) {
  }

  /**
   * Getters
   */
  private get spreadsheetId(): SSID {
    return SSID[`${this.collectionName}s`.toUpperCase()];
  }

  private get collectionRangeName(): string {
    return this.collectionSheet ? this.collectionSheet : `${this.collectionName}s`;
  }

  private get collectionRange(): string {
    return `${this.collectionRangeName}!${this.collectionRows ? this.collectionRows : '1:1000'}`;
  }

  private get headerRange(): string {
    return `${this.collectionRangeName}!1:1`;
  }

  /**
   * Getter for using AppsScriptApi to make calls to functions by name
   */
  private get partialUpdateFunctionName(): string {
    return `partialUpdate${this.collectionName}Data`;
  }

  /**
   * @description handle wait for auth and gapi sources
   */
  private hasGapiToken$() {
    return this.store.select(AuthState.hasGapiToken)
      .pipe(
        skipWhile(hasToken => !hasToken),
      )
  }

  private watchSheetMetadata$() {
    return this.sheetsMetadataService
      .watchSheetMetadata(this.spreadsheetId)
      .pipe(
        distinctUntilKeyChanged('updatedAt'),
        // skipWhile((values) => values?.rowIndex == undefined || values?.endRow == undefined),
        filter(values => values?.rowIndex !== undefined && values?.endRow !== undefined),
      );
  }

  /// **************
  /// Handle the collections of data
  /// **************

  /**
   * @description Calls SheetsApiService to request by range
   * @param  {} this.collectionRange
   * @returns T[] Entire "DataRange = all values" of requested sheet.
   * Note only:  I believe this is only called during initial load.
   */
  private async collection(): Promise<T[]> {
    return await this.sheetsApiService
      .getDataRange<T>(this.spreadsheetId, this.collectionRange);
  }


  /**
   * @description Wait for gapi token to exist and then return collection as observable
   */
  collection$(): Observable<T[]> {
    return this.hasGapiToken$()
      .pipe(
        filter(v => v),
        switchMap(() => from(this.collection()))
      )
  }

  docs$(): Observable<T[]> {
    return this.watchSheetMetadata$()
      .pipe(
        switchMap((values) => from(this.docs(values))),
      )
  }



  /**
   * @description Serialize the 2D array from the Sheets Api into an object to be passed to a Collections Model constructor
   * @param param0
   * @returns
   */
  private async docs({ rowIndex: _rowIndex, endRow }: SheetsMetadata) {
    const range = `${this.collectionRangeName}!${_rowIndex}:${endRow}`;
    const doc = await this.sheetsApiService
      .getHeaderAndRowValues(this.spreadsheetId, this.headerRange, range);
    const [headers, rows] = doc;
    return rows.map((row, index) => headers.reduce((accumulator, currentValue, currentIndex) => {
      accumulator[currentValue.toString().trim()] = row[currentIndex];
      return accumulator;
    }, {
      rowIndex: _rowIndex + index,
    })) as T[];
  }

  /// ************
  /// Hanlde query of data
  /// ************



  /// **************
  /// Write Data
  /// **************

  async create(value: T, uid?: string) {
    this.showStatus('Creating...');
    uid = uid || null;
    try {
      await this.sheetsApiService.appendDataRow(this.spreadsheetId, this.headerRange, {
        ...value,
        updatedAt: this.timestamp,
        createdAt: this.timestamp,
        // updatedBy: 'JTMS System',
        updatedBy: `[JTMS]_${this.store.selectSnapshot(AuthState.user).email}`,
      }).then((response) => {
        console.log("SheetsApi.appendRow", { response })
        this.showStatus('Created');

        const metadata = this.parseUpdatedRange(response?.updates?.updatedRange);

        this.updateSheetsMetadata(metadata);

        if (isDevMode()) {
          console.groupCollapsed(`${this.serviceName} Service [${this.collectionName}] [create]`);
          console.log('[uid]', uid, value);
          console.groupEnd();
        }
      });

    } catch (error) {
      console.error(error);
      this.showStatus('An Error ocurred');
    }
  }

  async batchUpdate(rows: T[]) {
    const values = [];

    rows.map(row => values.push({
      ...row,
      updatedAt: this.timestamp,
      updatedBy: 'JTMS System Batch',
    }))

    // this.sheetsApiService.batchUpdateSheetRows(
    //   this.spreadsheetId,
    //   this.collectionName,
    //   this.headerRange,
    //   values,
    // )
  }

  async update(uid: string, value: Partial<T>) {
    this.showStatus('Updating...');
    try {
      const _ = await this.appsScriptService.callScriptFunction({
        functionName: this.partialUpdateFunctionName,
        parameters: [
          uid,
          {
            ...value,
            updatedAt: this.timestamp,
            // updatedBy: 'JTMS System',
            updatedBy: `[JTMS]_${this.store.selectSnapshot(AuthState.user).email}`,
          }
        ]
      });
      this.showStatus('Updated!');
      // if (isDevMode()) {
      //   console.groupCollapsed(`${this.serviceName} Service [${this.collectionName}] [update]`);
      //   console.log('[UID]', uid, value);
      //   console.groupEnd();
      // }
    } catch (error) {
      console.error(error);
      this.showStatus('Error ocurred');
    }
  }

  upsert(ref: string, data: T) {
    return ref ? this.update(ref, data) : this.create(data);
  }

  async delete(uid: string) {
    this.showStatus('Deleting...');
    try {
      const _ = await this.appsScriptService.callScriptFunction({
        functionName: this.partialUpdateFunctionName,
        parameters: [
          uid,
          {
            status: 'DELETE ME',
            updatedAt: this.timestamp,
            // updatedBy: 'JTMS System',
            updatedBy: `[JTMS]_${this.store.selectSnapshot(AuthState.user).email}`,
          }
        ]
      });
      this.showStatus('Deleted');
      if (isDevMode()) {
        console.groupCollapsed(`${this.serviceName} Service [${this.collectionName}] [delete]`);
        console.log('[uid]', uid);
        console.groupEnd();
      }
    } catch (error) {
      console.error(error);
      this.showStatus('An Error ocurred');
    }
  }

  // API Functions
  upsertWithSheetsApi(ref: string, data: T) {
    return ref ? this.updateWithSheetsApi(data) : this.create(data);
  }

  async updateWithSheetsApi(value: T, uid?: string) {
    this.showStatus('Updating...')
    // console.log(value);
    const { rowIndex } = value;
    await this.sheetsApiService.updateDataRow(
      this.spreadsheetId,
      this.headerRange,
      `${this.collectionRangeName}!A${rowIndex}:${rowIndex}`,
      {
        ...value,
        updatedAt: this.timestamp,
        updatedBy: `[JTMS-SheetsApi]_${this.store.selectSnapshot(AuthState.user).email}`,
      }
    ).then(async response => {
      // console.log("SheetsApi.update", { response });

      const metadata = this.parseUpdatedRange(response?.updatedRange);

      this.updateSheetsMetadata(metadata);

      if (isDevMode()) {
        console.groupCollapsed(`${this.serviceName} Service [${this.collectionName}] [update]`);
        console.log('[UID]', uid, value);
        console.groupEnd();
      }
    }).catch((error) => {
      console.error(JSON.stringify(error));
      this.showStatus('An Error occured');
    })
  }

  /// **************
  /// Helpers
  /// **************
  private async updateSheetsMetadata(metadata: Partial<SheetsMetadata>) {
    const result = await this.sheetsMetadataService.updateSheetMetadata(this.spreadsheetId, {
      append: false,
      ...metadata,
      sheetName: this.collectionRangeName,
    });
  }

  private parseUpdatedRange(range: string): Partial<SheetsMetadata> {
    const stringRange = range.split('!')[1].split(':');
    const [startRow, endRow] = stringRange;
    return {
      rowIndex: Number(startRow.replace(/\D/g, '')),
      endRow: Number(endRow.replace(/\D/g, '')),
    }
  }

  private get timestamp(): Date {
    return new Date();
  }

  public async checkFolder(resource, parent?: string): Promise<string> {
    const { folderId, title } = resource;
    if (folderId) {
      return folderId;
    } else {
      const parentId = FOLDERID[`${this.collectionName}s`] ? FOLDERID[`${this.collectionName}s`.toUpperCase()] : parent;
      const folder = await this.driveApi.createFolder(parentId, title);
      return folder.Id;
    }
  }

  public async deleteFile(id: string) {
    await this.driveApi.delete(id);
  }

  public getFiles(folderId: string) {
    return folderId ? this.driveApi.getFiles(folderId) : [];
  }

  private showStatus(message: string) {
    this.snackbar.openSnackBar(`${this.collectionName.toUpperCase()} ${message}`, null);
  }
}
