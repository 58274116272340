import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from '@angular/fire/firestore';
import { Job } from './job';
import { Task } from './task';
import { BaseData } from './_global';
import { DRIVE_FOLDER_URL } from '@dis/gapi';
import { SPACE_URL } from './chat';

export enum PROJECTSTATUS {
  QUOTING = 'QUOTING',
  QUOTED = 'QUOTED',
  LOOKAHEAD = 'LOOK AHEAD',
  PLANNING = 'PLANNING',
  INPROGRESS = 'IN PROGRESS',
  COMPLETE = 'COMPLETE',
  DEAD = 'DEAD',
}

export interface Project extends BaseData {
  projectId: string;
  projectNumber: string;
  customer: string;
  well: string;
  rig: string;
  location: string;
  network: string;
  ocsgLease: string;
  status: PROJECTSTATUS;
  notes: string;
  space: string;
  folderId: string;
  jobs: Job[];
  tasks: Task[];
  list: string;
  areaOfOperation: string;
  logId: string;
  sections: string;
  spudDate: Date;
  dcid: string;
  updatedBy: string;
  metadata: ProjectMetadata;
}

export class Project extends BaseData {
  constructor(
    data: Partial<Project>,
  ) {
    super(data);
    this.uid = data.projectNumber || null;
    this.projectId = data.projectId || null;
    this.projectNumber = data.projectNumber || null;
    this.folderId = data.folderId || null;
    this.customer = data.customer || null;
    this.network = data.network || null;
    this.ocsgLease = data.ocsgLease || null;
    this.location = data.location || null;
    this.well = data.well || null;
    this.rig = data.rig || null;
    this.status = data.status || null;
    this.space = data.space || null;
    this.notes = data.notes || null;
    this.jobs = data.jobs || [];
    this.tasks = data.tasks || [];
    this.list = data.list || null;
    this.areaOfOperation = data.areaOfOperation || null;
    this.logId = data.logId || null;
    this.sections = data.sections || null;
    this.spudDate = data.spudDate ? new Date(data.spudDate) : null;

    this.metadata = data.metadata || null;
    this.dcid = data.dcid || null;

    this.fromSpreadsheet(data);
  }

  public get bhaSections() {
    return this.sections?.split(',');
  }

  public get title() {
    return this.projectNumber;
  }

  public get splitProjectNumber() {
    return this.projectNumber?.split('-').slice(1).join('-');
  }

  public get spaceUrl() {
    if (this.space) {
      const spaceId = this.space.split('/')[1];
      return `${SPACE_URL}${spaceId}`;
    }
    return null;
  }

  public get folderUrl() {
    return `${DRIVE_FOLDER_URL}${this.folderId}`;
  }

  public get logUrl() {
    return `https://docs.google.com/document/d/${this.logId}`;
  }

  private fromSpreadsheet(project: Partial<Project>) {

    if (typeof project.metadata === 'string') {
      this.metadata = JSON.parse(project.metadata);
    }

  }

  static toSpreadsheet(project: Project) {
    //@ts-expect-error
    project.metadata = JSON.stringify(project.metadata);
    // console.log(job);
    return project;
  }

}

export const projectConverter = {
  toFirestore(project: Project): DocumentData {
    return { ...project };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Project {
    const data = snapshot.data(options);
    return new Project(data);
  }
};

export interface ProjectMetadata {
  stagedBool: boolean;
  spcCreatedBool: boolean;
  spcApprovedBool: boolean;
}
