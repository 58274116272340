import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';


export function provideFirebase(environment: any) {
  return [
    provideFirebaseApp(() => {
      const app = initializeApp(environment.firebase);
      return app;
    }),
    // ...[
    //   environment.useEmulators
    //     ? [AppCheckModule]
    //     : [
    //       provideAppCheck(() => {
    //         const provider = new ReCaptchaV3Provider(environment.appCheck.recaptcha3SiteKey);
    //         return initializeAppCheck(getApp(), { provider, isTokenAutoRefreshEnabled: true });
    //       })
    //     ]
    // ],
    provideRemoteConfig(() => getRemoteConfig()),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators) {
        // connectAuthEmulatorInDevMode(auth);
      }
      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.useEmulators) {
        // connectFirestoreEmulatorInDevMode(firestore);
      }
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (environment.useEmulators) {
        // connectFunctionsEmulatorInDevMode(functions);
      }
      return functions;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        // connectStorageEmulatorInDevMode(storage);
      }
      return storage;
    }),
    providePerformance(() => getPerformance()),
    ScreenTrackingService,
    UserTrackingService,
  ];
}
