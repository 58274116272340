import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, SwPush } from '@angular/service-worker';

import { concat, first, interval } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NgswService {
  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    private snackbar: MatSnackBar) {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everyHour$ = interval(1 * 60 * 60 * 1000);
    const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

    if (this.swUpdate.isEnabled) {
      everyHourOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await this.swUpdate.checkForUpdate();
          console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }
  }

  initSwUpdate() {

    if (this.swUpdate.isEnabled) {
      console.log('Ngsw Initialized', this.swUpdate);

      this.swUpdate.versionUpdates.subscribe(evt => {
        console.log('Ngsw.versionUpdates', evt);
        switch (evt.type) {
          case 'VERSION_DETECTED':
            const message = `Downloading new app version: ${evt.version.hash}`
            console.log(message);
            this.snackbar.open(message);
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
            const snack = this.snackbar.open('App Update Available', 'Reload');
            snack
              .onAction()
              .subscribe(() => document.location.reload());
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
        }
      });

      this.swUpdate.unrecoverable.subscribe(event => {
        const message = `An error occurred that we cannot recover from:\n ${event.reason} \n\nPlease reload the page.`;
        console.error(message);
        this.snackbar.open(message);
      })

    }
    return 'initSwUpdate';
  }

  initSwPush() {
    if (this.swPush.isEnabled) {
      console.log('SwPush Initialized');
      this.swPush.messages.subscribe((message) => console.log('SwPush.messages', JSON.stringify(message)))
    }
  }
}
