import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { GoogleChartsModule } from 'angular-google-charts';
import { FileInfo, GoogleDriveApiService } from '@dis/gapi';
import { PressureChartConfig } from './chart-config.model';

@Component({
  selector: 'lib-view-graph',
  standalone: true,
  imports: [
    GoogleChartsModule,
  ],
  templateUrl: './view-graph.component.html',
  styleUrl: './view-graph.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewGraphComponent implements OnInit {

  chartConfig = signal<PressureChartConfig>(null);
  driveApi = inject(GoogleDriveApiService);
  file = input<FileInfo>();

  async ngOnInit(): Promise<void> {
    const file = this.file();
    const data = await this.fecthData(file.Id);
    const chart = new PressureChartConfig(file);
    // TODO: make this more dynamic or learn how to get csv as actual data types.
    chart.setData(data);
    this.chartConfig.set(chart);
  }

  onReady(event: any) {
    // console.log('Chart Ready', event);
  }

  private async fecthData(fileId: string) {
    const data = await this.driveApi.getCsvFile(fileId);
    return data as [string, string, string, string][];
  }
}
