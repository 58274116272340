import { ArrayDate } from "./_global";

export interface JobDates {
    // Shipment Dates
    containerDates: ArrayDate[];
    shipmentDates: ArrayDate[];
    rentalDates: ArrayDate[];
    // Other dates
    spudDate: Date;
    estimatedShipDate: Date;
    estimatedRentalStartDate: Date;
    returningDate: Date;
    jobStartDate: Date;
    jobEndDate: Date;
    shopCompleteByDate: Date;
}
export class JobDates {
    constructor(data: Partial<JobDates> = {},) {
        // Date Array Fields
        this.containerDates = data.containerDates ? data.containerDates.map(dateObj => {
            dateObj.start = dateObj.start ? new Date(dateObj.start) : null;
            dateObj.stop = dateObj.stop ? new Date(dateObj.stop) : null;
            return dateObj;
        }) : null;

        this.shipmentDates = data.shipmentDates ? data.shipmentDates.map(dateObj => {
            dateObj.start = dateObj.start ? new Date(dateObj.start) : null;
            dateObj.stop = dateObj.stop ? new Date(dateObj.stop) : null;
            return dateObj;
        }) : null;

        this.rentalDates = data.rentalDates ? data.rentalDates.map(dateObj => {
            dateObj.start = dateObj.start ? new Date(dateObj.start) : null;
            dateObj.stop = dateObj.stop ? new Date(dateObj.stop) : null;
            return dateObj;
        }) : null;

        // Date Fields
        this.spudDate = data.spudDate ? new Date(data.spudDate) : null;
        this.estimatedShipDate = data.estimatedShipDate ? new Date(data.estimatedShipDate) : null;
        this.estimatedRentalStartDate = data.estimatedRentalStartDate ? new Date(data.estimatedRentalStartDate) : null;
        this.returningDate = data.returningDate ? new Date(data.returningDate) : null;
        this.jobStartDate = data.jobStartDate ? new Date(data.jobStartDate) : null;
        this.jobEndDate = data.jobEndDate ? new Date(data.jobEndDate) : null;
        this.shopCompleteByDate = data.shopCompleteByDate ? new Date(data.shopCompleteByDate) : null;
    }
}

