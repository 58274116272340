import { FileInfo } from "@dis/gapi";
import { ChartType } from "angular-google-charts";

export class PressureChartConfig {
  id: string;
  title: string;
  type: ChartType;
  columnNames: Array<{ type: string, label?: string, role?: string }>;
  options: {
    hAxis: { title: string, minValue: number },
    vAxis: { title: string, maxValue: number, minValue: number },
    legend: { position: string },
    tooltip: { isHtml: boolean, trigger: string }
  };
  private _data: [number, number, string, boolean][];

  constructor(file: FileInfo) {
    this.id = file.Id;
    this.title = file.Name.split('.csv')[0];
    this.type = ChartType.LineChart;
    this._data = [];
    this.columnNames = [
      { type: 'number', label: 'Time' },
      { type: 'number', label: 'PSI' },
      { type: 'string', role: 'annotation' },
      { type: 'boolean', role: 'emphasis' }
    ];
    this.options = {
      hAxis: { title: 'Time (seconds)', minValue: 0 },
      vAxis: { title: 'Pressure (psi)', maxValue: 500, minValue: 300 },
      legend: { position: 'none' },
      tooltip: { isHtml: true, trigger: 'selection' }
    };
  }

  get data() {
    return this._data;
  }

  setData(csvData: [string, string, string, string][]) {
    this._data = csvData.map(row => [
      parseInt(row[0], 10), // time column
      parseInt(row[1], 10), // psi column
      !!row[2] ? row[2] : null, // annotation column
      row[3] === "true" // emphasis column
    ])
  }
}
