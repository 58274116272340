<mat-toolbar role="heading" class="header" color="primary">

  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="sidenavService.toggle()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>

  @if (!isHandset()) {
  <span>Job & Tool Management System</span>
  <img class="logo" src="../../../assets/images/logo/drilling-logo.jpg" alt="DIS_Logo">
  } @else {
  <span>JTMS</span>
  }

  <span class="span"></span>

  @defer{
  <jtms-active-pressure-test />
  }

  <span class="span"></span>

  @if (user(); as user) {
  <img class="user-photoURL" [src]="user.picture" [routerLink]="['/user', user.user_id]">
  }

  <button type="button" aria-label="About JTMS" mat-icon-button [routerLink]="['/about']">
    <mat-icon aria-label="About JTMS">info</mat-icon>
  </button>

</mat-toolbar>
