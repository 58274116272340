import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Observable } from 'rxjs';

interface AlertMessage {
  enabled: boolean;
  message: string;
}

@Component({
  selector: 'jtms-alert-header',
  standalone: true,
  imports: [
    AsyncPipe,
    MatToolbarModule,
  ],
  templateUrl: './alert-header.component.html',
  styleUrl: './alert-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertHeaderComponent {
  private firestore = inject(Firestore);
  public alertMessage$ = <Observable<AlertMessage>>docData(doc(this.firestore, 'appMetadata/alertMessage'));
}
